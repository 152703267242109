import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";
import OptionCard from "./OptionCard";

const ProductSelection = ({
  userId,
  token,
  url,
  hasAnyAccess,
  hasAnyWorkshop,
  hasPhysical,
  hasAllAccess,
  physical,
  mndOnly,
  workshopAndMndDisc,
  isIndustry,
  sst,
}) => {
  const [sessionOption, setSessionOption] = useState("");

  const [selected, setSelected] = useState(null);
  const [selectedMndOnly, setSelectedMndOnly] = useState(null);
  const [selectedWorkshopAndMndDisc, setSelectedWorkshopAndMndDisc] =
    useState(null);

  const disabledSubmit = {
    fullCongress: !(selected && selectedWorkshopAndMndDisc),
    mainCongress: !selected,
    day1MndOnly: !selectedMndOnly,
    addOnMndDiscWorkshops: !selectedWorkshopAndMndDisc,
  };

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedMndOnly, selectedWorkshopAndMndDisc]}
        />

        {sessionOption && (
          <button
            type="button"
            className="back-link btn btn-link"
            onClick={() =>
              setSessionOption("") +
              setSelected(null) +
              setSelectedWorkshopAndMndDisc(null) +
              setSelectedMndOnly(null)
            }
          >
            ← Back
          </button>
        )}

        {!sessionOption && (
          <OptionCard
            sessionOption={sessionOption}
            setSessionOption={setSessionOption}
            hasPhysical={hasPhysical}
            hasAnyAccess={hasAnyAccess}
            hasAnyWorkshop={hasAnyWorkshop}
            hasAllAccess={hasAllAccess}
            isIndustry={isIndustry}
          />
        )}

        {sessionOption && (
          <>
            {/* fullCongress */}
            {sessionOption === "fullCongress" && (
              <>
                <ProductCard
                  cardTitle={"Day 2 and Day 3 Main Congress"}
                  products={physical}
                  selectedState={selected}
                  setSelectedState={setSelected}
                  activated={!hasPhysical}
                  handleCheckbox={(e) => {
                    if (!e.target.checked) {
                      setSelected(null);
                      setSelectedWorkshop(null);
                      setSelectedMndDisc(null);
                    }
                  }}
                  handleRadio={() => {
                    setSelectedMndOnly(null);
                  }}
                />

                <ProductCard
                  cardTitle={"Day 1 Workshops"}
                  products={workshopAndMndDisc}
                  selectedState={selectedWorkshopAndMndDisc}
                  setSelectedState={setSelectedWorkshopAndMndDisc}
                  activated={true}
                  handleCheckbox={(e) => {
                    if (!e.target.checked) {
                      setSelectedWorkshopAndMndDisc(null);
                    }
                  }}
                  handleRadio={() => {}}
                />

                {/*<small
                  className="d-block text-center mx-auto"
                  style={{ maxWidth: "650px" }}
                >
                  * You are allowed to swap between the 12th Care Forum & The
                  Joint Australia-New Zealand MND Research Symposium sessions
                  onsite as you see fit.
                </small>*/}
              </>
            )}

            {/* mainCongress */}
            {sessionOption === "mainCongress" && (
              <>
                <ProductCard
                  cardTitle={"PACTALS Congress 2025"}
                  products={physical}
                  selectedState={selected}
                  setSelectedState={setSelected}
                  activated={!hasPhysical}
                  handleCheckbox={(e) => {
                    if (!e.target.checked) {
                      setSelected(null);
                    }
                  }}
                  handleRadio={() => {
                    setSelectedMndOnly(null);
                  }}
                />
              </>
            )}

            {/* day1MndOnly */}
            {sessionOption === "day1MndOnly" && (
              <>
                <ProductCard
                  cardTitle={"Day 1 MND"}
                  products={mndOnly}
                  selectedState={selectedMndOnly}
                  setSelectedState={setSelectedMndOnly}
                  activated={true}
                  handleCheckbox={(e) => {
                    if (!e.target.checked) {
                      setSelectedMndOnly(null);
                    }
                  }}
                  handleRadio={() => {}}
                />

                <small
                  className="d-block text-center mx-auto"
                  style={{ maxWidth: "650px" }}
                >
                  * You are allowed to swap between the 12th Care Forum & The
                  Joint Australia-New Zealand MND Research Symposium sessions
                  onsite as you see fit.
                </small>
              </>
            )}

            {/* addWorkshop */}
            {sessionOption === "addOnMndDiscWorkshops" && (
              <>
                <ProductCard
                  cardTitle={"Day 1 Workshops"}
                  products={workshopAndMndDisc}
                  selectedState={selectedWorkshopAndMndDisc}
                  setSelectedState={setSelectedWorkshopAndMndDisc}
                  activated={true}
                  handleCheckbox={(e) => {
                    if (!e.target.checked) {
                      setSelectedWorkshopAndMndDisc(null);
                    }
                  }}
                  handleRadio={() => {}}
                />

                {/*<small
                  className="d-block text-center mx-auto"
                  style={{ maxWidth: "650px" }}
                >
                  * You are allowed to swap between the 12th Care Forum & The
                  Joint Australia-New Zealand MND Research Symposium sessions
                  onsite as you see fit.
                </small>*/}
              </>
            )}

            {sst && (
              <strong className="d-block mx-auto">
                All purchases will incur 8% SST
              </strong>
            )}

            <button
              type="submit"
              className="btn text-white btn-primary d-block mx-auto mt-4"
              disabled={disabledSubmit[sessionOption]}
            >
              Purchase Pass
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default ProductSelection;
